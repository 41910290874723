const bannerHeight = $('.js-banner').outerHeight();
const body = $('body');
const ww = $(window).width();
let positionFix = 270;

if (ww < 768) {
	positionFix = 160;
}

if ($('.js-headerTop').length > 0) {
	if (ww > 767) {
		$(window).on('scroll load', () => {
			const scrolled = $(window).scrollTop();

			if (scrolled > bannerHeight) {
				body.addClass('header_is_sticky');
			} else {
				body.removeClass('header_is_sticky');
			}
		});
	}

} else {
	let scrolled = $(window).scrollTop();
	if (scrolled > positionFix) {
		body.addClass('header_is_sticky');
	} else {
		body.removeClass('header_is_sticky');
	}

	$(window).on('scroll', () => {
		scrolled = $(window).scrollTop();
		if (scrolled > positionFix) {
			body.addClass('header_is_sticky');
		} else {
			body.removeClass('header_is_sticky');
		}
	});
}
