let maskPlaceholder = '+7 (999) 999-99-99';

$('.js-mask').each((index, element) => {
	var _this = $(element);

	if(_this.data('mask')){
		maskPlaceholder = _this.data('mask');
	}

	_this.inputmask({'mask': maskPlaceholder, showMaskOnHover: false});
});

$(document).on('keydown', '.js-mask', function(event){
	if(event.which === 32) return false;
});


// fix mac phone focus input
$(document).one('focus', '.js-mask', function() {
	var _this = $(this);
	
	setTimeout(() => {
		_this[0].setSelectionRange(4, 4);
	}, 50)
});
