const menu = $('.js-menu');
const body = $('body');

$('.js-openMenu').on('click', function() {
	const _this = $(this);

	menu.toggleClass('is_open');
	_this.toggleClass('is_active');
	body.toggleClass('menu_open');
});

$(document).on('click', function(e) {
	var div = $(".header"); // тут указываем ID элемента
	if (!div.is(e.target) // если клик был не по нашему блоку
	    && div.has(e.target).length === 0) { // и не по его дочерним элементам
		menu.removeClass('is_open');
		$('.js-openMenu').removeClass('is_active');
		body.removeClass('menu_open');
	}
})

// $(document).mouseup(function (e){ // событие клика по веб-документу
// 	var div = $(".header"); // тут указываем ID элемента
// 	if (!div.is(e.target) // если клик был не по нашему блоку
// 	    && div.has(e.target).length === 0) { // и не по его дочерним элементам
// 		menu.removeClass('is_open');
// 		$('.js-openMenu').removeClass('is_active');
// 		body.removeClass('menu_open');
// 	}
// });
