$.extend($.validator.messages, {
	required: "Это поле обязательно для заполнения.",
	remote: "Пожалуйста, введите правильное значение.",
	email: "Указан неверный формат e-mail адреса.",
	url: "Пожалуйста, введите корректный URL.",
	date: "Пожалуйста, введите корректную дату.",
	dateISO: "Пожалуйста, введите корректную дату в формате ISO.",
	number: "Пожалуйста, введите число.",
	digits: "Пожалуйста, вводите только цифры.",
	creditcard: "Пожалуйста, введите правильный номер кредитной карты.",
	equalTo: "Пожалуйста, введите такое же значение ещё раз.",
	extension: "Пожалуйста, выберите файл с правильным расширением.",
	maxlength: $.validator.format( "Максимальная длина описания - {0} символов." ),
	minlength: $.validator.format( "Пожалуйста, введите не меньше {0} символов." ),
	rangelength: $.validator.format( "Пожалуйста, введите значение длиной от {0} до {1} символов." ),
	range: $.validator.format( "Пожалуйста, введите число от {0} до {1}." ),
	max: $.validator.format( "Максимальное число - {0}." ),
	min: $.validator.format( "Пожалуйста, введите число, большее или равное {0}." ),
});

$(window).on('load', function() {
	$('.js-validate').each((index, form) => {
		$(form).validate({
			ignore: ':hidden',
		    errorElement: 'span',
		    errorClass: 'label-error',
		    highlight: function(element) {
				setTimeout(() => {
					if(!$(element).attr('disabled')){
						$(element).parent().addClass('is-error');

						if($(element).attr('type') == 'checkbox' || $(element).attr('type') == 'radio'){
							const parent = $(element).parent();

							parent.append(parent.find('.label-error'));
						}
					}
				});
	        },
	        unhighlight: function(element) {
				setTimeout(() => {
					if(!$(element).attr('disabled')){
						if($(element).val() != '') $(element).parent().addClass('is-success');
						$(element).parent().removeClass('is-error');
					}
				});
	        }
		});
	});
})

/*
 * Валидация телефона
 */

$.validator.addMethod('phonecustom', function(value, element) {
	if(value == '+7 (___) ___-__-__') return true;
	return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/);
}, 'Указан неверный формат телефона');

$.validator.addClassRules('js-phone', {
	phonecustom: true
});